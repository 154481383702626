<template>
  <v-container>
    <v-card>
      <v-data-table :search="search" v-bind="bind.table">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-on="on.new" v-bind="bind.new">{{ labels.new }}</v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn v-bind="bind.actions" v-on="on.edit(item)">
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>
          <v-btn color="error" v-bind="bind.actions" v-on="on.delete(item)"
            ><v-icon>{{ icons.delete }}</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";
import Modal from "@/components/Modal/ModalProduct/Modal-TipoCobertura";

export default {
  name: "TipoCoberturasCRUD",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Código", value: "tipo_cobertura", align: "left" },
      { text: "Tipo Cobertura", value: "tipo_cobertura_desc", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    tableListItems: [],
  }),

  computed: {
    items() {
      return this.tableListItems;
    },
  },

  methods: {
    ...mapActions("NewProduct", [
      "getTipoCoberturas",
      "CrearTipoCobertura",
      "UpdateTipoCobertura",
      "deleteTipoCobertura",
    ]),

    async setup() {
      this.loading = true;
      try {
        const response = await this.getTipoCoberturas();
        this.tableListItems = response.coberturas;
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loading = false;
    },

    saveMethod() {
      return this.CrearTipoCobertura;
    },
    updateMethod() {
      return this.UpdateTipoCobertura;
    },
    deleteMethod() {
      return this.deleteTipoCobertura;
    },
  },
};
</script>
