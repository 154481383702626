<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close"
          ><v-icon>{{ icons.close }}</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-text-field
            v-model="form.tipo_cobertura"
            label="Código"
            placeholder="Escriba nombre del código..."
            :rules="[(v) => !!v || 'El campo código es requerido.']"
          ></v-text-field>

          <v-text-field
            v-model="form.tipo_cobertura_desc"
            name="tipoCobertura"
            label="Tipo cobertura"
            placeholder="Escriba nombre del tipo cobertura..."
            :rules="[(v) => !!v || 'El campo descripción es requerido.']"
          >
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">{{ labels.submit }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
export default {
  name: "TipoCoberturaCrudModall",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de tipos de coberturas",
      form: {
        tipo_cobertura: "",
        tipo_cobertura_desc: "",
      },
    };
  },
};
</script>
